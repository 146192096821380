<template>
  <pricing-tiers-form
    ref="pricing-tiers-form"
    :is-create-form="true"
  />
</template>

<script>

import PricingTiersForm from '../components/PricingTiersForm.vue'

export default {
  name: 'PriceTiersCreate',
  inject: ['pricingTiersModuleName'],
  components: {
    PricingTiersForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['pricing-tiers-form']) {
      this.$refs['pricing-tiers-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = {
      is_active: true,
      is_required: false,
    }

    this.$store.commit(`${this.pricingTiersModuleName}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PRICING_TIERS_FORM_CLONE`, initialData)
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME_CLONE,
    }
  },
}
</script>
